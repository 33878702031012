<template>
  <v-card flat class="mb-2 pa-2  lighten-5">
    <v-card-text>
      <v-row>
        <v-col xs="12">
          <v-progress-linear indeterminate color="primary" v-if="processing"></v-progress-linear>
          <v-subheader style="font-size: 16px!important;">Residual Promedio</v-subheader>
          <div ref="amchart" style="height: 45vh;"></div>
          <br />
          <v-alert
            dense
            type="warning"
            text
            v-show="!processing && isChartEmpty"
          >No existe información para esta gráfica</v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import apiDashboard from "@/api/dashboards";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";
import { store } from "@/store";
import helper from "@/api/empleados";

am4core.useTheme(am4themesAnimated);

export default {
  props: ["corteId", "tipoProducto"],
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      headers: [
        { text: "PERIODO", value: "Periodo" },
        { text: "VALOR", value: "Monto" }
      ]
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    }
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.colors.step = 6;
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        var periodo = [];
        dataParam.forEach(function(row) {
          if (periodo.indexOf(row.Corte) === -1) {
            periodo.push(row.Corte);
          }
        });

        periodo.forEach(function(d) {
          let rows = dataParam.filter(c => c.Corte === d);
          let newRow = {
            Periodo: d,
            Residual: 0
          };
          let montoResidual = 0;

          rows.forEach(function(c) {
            if (c) {
              montoResidual = (c.Monto / c.ConteoAct).toFixed(2);
            }
          });
          newRow["Residual"] = montoResidual;
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Periodo";
        categoryAxis.title.text = "Periodo";
        categoryAxis.renderer.minGridDistance = 20;
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Periodo";
        lineSeries.dataFields.valueY = "Residual";
        lineSeries.name = "Residual promedio";
        lineSeries.strokeWidth = 3;
        lineSeries.stroke = am4core.color("#F1C40F");
        var bullet = lineSeries.bullets.push(new am4charts.Bullet());

        bullet.propertyFields.rotation = "angle";

        var arrow = bullet.createChild(am4core.Triangle);
        arrow.horizontalCenter = "middle";
        arrow.verticalCenter = "middle";
        arrow.stroke = am4core.color("#fff");
        arrow.fill = am4core.color("#D35400");
        arrow.direction = "top";
        arrow.width = 10;
        arrow.height = 25;
        arrow.tooltipText = "{name}: [bold]{valueY}[/]";

        var hoverState = bullet.states.create("hover");
        hoverState.properties.scale = 1.7;

        this.chart = chart;
      } catch (e) {
        // empty
      }
    },
    refresh() {
      if (this.corteId) {
        this.processing = true;
        let param = {
          CorteId: this.corteId,
          TipoProductoList: Array.from(this.tipoProducto),
          Chart: "ResidualPromedio",
          AsociadoId: store.getters.user.asociado.id
        };

        apiDashboard
          .getChart(param)
          .then(response => {
            this.chartData = response.data;
            this.generateChart(this.chartData);
            this.processing = false;
          })
          .catch(error => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
    getName(list, id, value, text) {
      return helper.getName(list, id, value, text);
    }
  },
  watch: {
    corteId: function() {
      this.refresh();
    },
    tipoProducto: function() {
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>